import React from 'react'
import { Link, graphql } from 'gatsby'
import * as ds from '../uiLibrary'
import tonitLogoDark from '../images/tonit-logo-dark.png'
import HeadMeta from '../components/HeadMeta'
import * as Agreements from "tonitagreements"
import ReactLinkify from 'react-linkify'

const Privacy = ({ data }) => {
  const page = data.allPrivacyJson.edges[0].node
  return (
    <ds.FixedWidthRow>
      <HeadMeta
        pageTitle={page.pageTitle || 'Privacy Policy - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <Link to='/'><ds.CenteredImg src={tonitLogoDark} maxWidth={['50px', '50px', '100%']} /></Link>
      <ds.Column span={12}>
        <Agreements.PrivacyComponent render={(title, notice, sections, updatedAt) => {
          return <ReactLinkify>
            <ds.AgreementsTitle>{title}</ds.AgreementsTitle>
            <ds.AgreementsBody>Last updated at: {updatedAt}</ds.AgreementsBody>
            <ds.AgreementsSubheader>{notice}</ds.AgreementsSubheader>
            <ds.AgreementsHeader>Table of Contents</ds.AgreementsHeader>
            {
              sections.map((s, i) => {
                if (s.subheader === "") {
                  return <ds.AgreementsSubheader>
                    <ds.A href={`#section${i + 1}`} color={ds.colors.black}>
                      {s.header}
                    </ds.A>
                  </ds.AgreementsSubheader>
                } else {
                  return <div>
                    <ds.AgreementsSubheader>
                      <ds.A href={`#section${i + 1}`} color={ds.colors.black}>
                        {s.header}
                      </ds.A>
                    </ds.AgreementsSubheader>
                    <ol>
                      <ds.AgreementsBody style={{ display: "inline" }} key={i}>
                        <ds.A href={`#section${i + 1}`} color={ds.colors.black}>
                          {i + 1}. {s.subheader}
                        </ds.A>
                      </ds.AgreementsBody>
                    </ol>
                  </div>
                }
              }
              )}
            {
              sections.map((s, i) =>
                <>
                  <ds.AgreementsHeader key={i} id={`section${i + 1}`}>{s.header}</ds.AgreementsHeader>
                  <ds.AgreementsSubheader key={i} id={`section${i + 1}`}>{i + 1}. {s.subheader}</ds.AgreementsSubheader>
                  <ds.AgreementsBody key={i}>{s.body}</ds.AgreementsBody>
                </>)
            }
          </ReactLinkify>;
        }} />
        <br />
        <Link to='/privacy-policy'><ds.P textAlign='center' color="black">Back to top</ds.P></Link>
      </ds.Column>
    </ds.FixedWidthRow>
  )
}

export const query = graphql`
  query {
          allPrivacyJson {
          edges {
          node {
          legal
          pageTitle
      pageDescription
      socialTitle
      socialDescription
      socialImage
    }
  }
}
}
`

export default Privacy;